<!-- @format -->

<template>
	<div>
		<layout-default-new>
			<section>
				<b-field class="margin-top-20" label="Select report date">
					<b-datepicker
						v-model="reportData.beginningDate"
						:show-week-number="showWeekNumber"
						:locale="locale"
						:first-day-of-week="firstDayOfWeek"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<b-field label="Select ending date">
					<b-datepicker
						v-model="reportData.endingDate"
						:show-week-number="showWeekNumber"
						:locale="locale"
						:first-day-of-week="firstDayOfWeek"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<b-field label="Report Type">
					<section>
						<div class="block">
							<b-radio
								v-model="radio"
								name="name"
								native-value="collectedPaymentReport"
							>
								Inbetalningar
							</b-radio>
							<b-radio v-model="radio" name="name" native-value="payoutReport">
								Utbetalningar
							</b-radio>
							<b-radio v-model="radio" name="name" native-value="setOffReport">
								Övriga - Kivitteringar
							</b-radio>
						</div>
						<p class="content">
							<b>Selection:</b>
							{{ radio }}
						</p>
					</section>
				</b-field>
				<b-button
					class="is-primary margin-top-20"
					type="submit"
					@click="getData"
					>Show payments that will be added to the report</b-button
				>
				<div v-if="loading">Loading......</div>
				<b-table
					:data="tableData"
					:columns="columns"
					class="smallFonts tale-style margin-top-15"
				>
				</b-table>
				<div>
					<b-button
						class="is-success margin-top-15"
						type="submit"
						@click="createHeader"
					>
						Create Collected Payments Report for the date
						{{ dateformat(reportHeaders.reportDate, 'yyyy-mm-dd') }}</b-button
					>
				</div>
			</section>
		</layout-default-new>
	</div>
</template>

<script>
import CreateCollectPaymentReportHeaderMutation from './graphql/mutation/createCollectPaymentReportHeader.mutation.gql'
import GetCollectedPaymentsListToViewQuery from './graphql/query/getCollectedPaymentsListToView.query.gql'
import UpdateBookKeepHeadersTableInsertReportNumberMutation from './graphql/mutation/updateBookKeepHeadersTableInsertReportNumber.mutation.gql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { computed, reactive, ref, onMounted } from '@vue/composition-api'
import Store from '@/store'
import dateformat from 'dateformat'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
import storeAccounting from '../accounting-store/storeAccounting'
import Router from '@/router'

export default {
	name: 'CreateCollectedPaymentsReport',
	setup() {
		onMounted(() => {
			setInitialState()
		})
		const setInitialState = () => {
			reportHeaders.tableLinesSubLines = []
			reportHeaders.countTransactions = 0
			reportHeaders.creditAmount = 0
			reportHeaders.creditExchangeAmount = 0
			reportHeaders.coverLines = []
			reportHeaders.coverDebitTotals = 0
			reportHeaders.coverCreditTotals = 0
			reportHeaders.docDefinition = []
			reportHeaders.reportDate = dateformat(reportData.endingDate, 'yyyy-mm-dd')
			reportHeaders.reportType = 'Inbetalningsjournal nr.'
			reportHeaders.reportNumber = 0
			reportHeaders.ourCompany = ''
			reportHeaders.coverTitle = 'Bokföringsunderlag'
			radio.value = 'collectedPaymentReport'
		}
		const radio = ref('collectedPaymentReport')
		const reportType = computed(() => radio.value)
		const { reportHeaders, reportData } = storeAccounting()
		const showWeekNumber = false
		const locale = 'sv-SE'
		const beginningDateVariable = ref('')
		const endingDateVariable = ref('')
		const fetchVariableDates = (bd, ed) => {
			return new Promise((resolve, reject) => {
				if ((bd && ed) !== null || undefined) {
					beginningDateVariable.value = dateformat(
						reportData.beginningDate,
						'yyyy-mm-dd',
					)
					endingDateVariable.value = dateformat(
						reportData.endingDate,
						'yyyy-mm-dd',
					)
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// Get daily payments for view
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch } = useQuery(
			GetCollectedPaymentsListToViewQuery,
			() => ({
				bd: beginningDateVariable.value,
				ed: endingDateVariable.value,
				report_type: reportType.value,
				our_company: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const { floatedCurrencyAddedNumber } = useReportNumber()
		const tableValues = ref([])
		const bookKeepIdsArray = ref([])
		const getTableValues = (qResult) => {
			console.log('qResult', qResult)
			tableValues.value = qResult.data.book_keep_headers.map((item) => {
				return {
					date: item.book_keep_date,
					documentNumber: item.document_number,
					companyId: item.customer_or_supplier_id,
					companyTitle: item.customer_or_supplier_title,
					paymentAmount: floatedCurrencyAddedNumber(item.payment_credit_amount),
					type: item.transaction_type,
				}
			})
			console.log('tableValues.value', tableValues.value)
			bookKeepIdsArray.value = qResult.data.book_keep_headers.map((item) => {
				return item.book_keep_id
			})
			console.log('bookKeepIdsArray.value', bookKeepIdsArray.value)
		}
		const tableData = computed(() => tableValues.value)
		const columns = [
			{
				field: 'date',
				label: 'Datum',
				width: '120',
			},
			{
				field: 'documentNumber',
				label: 'Dokument Nr.',
				width: '120',
			},
			{
				field: 'companyId',
				label: 'Kundnummer',
				width: '120',
			},
			{
				field: 'companyTitle',
				label: 'Kundnamn',
				width: '120',
			},
			{
				field: 'paymentAmount',
				label: 'Bellop',
				width: '120',
			},
			{
				field: 'type',
				label: 'Typ',
				width: '70',
			},
		]
		const getData = () => {
			fetchVariableDates()
				.then(() => refetch())
				.then((result) => getTableValues(result))
		}
		const { mutate: createNewReportHeader, onDone: onDoneHeaders } =
			useMutation(CreateCollectPaymentReportHeaderMutation, () => ({
				variables: {
					our_company: Store.getters.getUserCurrentCompany,
					report_date: dateformat(reportData.endingDate, 'yyyy-mm-dd'), // reportHeaders.reportDate,
					report_type: reportType.value,
				},
			}))
		const getReportType = (payload) => {
			switch (payload) {
				case 'collectedPaymentReport':
					return 'Inbetalningsjournal nr. '
				case 'payoutReport':
					return 'Utbetalningsjournal nr. '
				case 'setOffReport':
					return 'Övriga transaktionsjournal nr. '
				default:
					return 'Övriga transaktionsjournal nr. '
			}
		}
		onDoneHeaders((result) => {
			console.log('result', result)
			reportHeaders.ourCompany =
				result.data.insert_collect_payment_report_headers.returning[0].our_company
			reportHeaders.reportDate =
				result.data.insert_collect_payment_report_headers.returning[0].report_date
			reportHeaders.reportNumber =
				result.data.insert_collect_payment_report_headers.returning[0].report_number
			reportHeaders.reportType = getReportType(
				result.data.insert_collect_payment_report_headers.returning[0]
					.report_type,
			)
			reportNumber.value =
				result.data.insert_collect_payment_report_headers.returning[0].report_number
			console.log('reportHeaders', reportHeaders)
		})
		const bookKeepId = ref('')
		const reportNumber = ref(0)
		const { mutate: insertReportNumber, onDone: onDoneInsertReportNumber } =
			useMutation(UpdateBookKeepHeadersTableInsertReportNumberMutation, () => ({
				variables: {
					book_keep_id: bookKeepId.value,
					report_number: reportNumber.value,
				},
			}))
		onDoneInsertReportNumber((result) =>
			console.log('Report number is inserted', result),
		)
		const insertReportNumberPromise = () => {
			return new Promise((resolve, reject) => {
				if (bookKeepIdsArray.value !== null || undefined) {
					bookKeepIdsArray.value.forEach((item) => {
						bookKeepId.value = item
						insertReportNumber()
					})
					resolve(bookKeepIdsArray.value)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const createHeader = () => {
			createNewReportHeader()
				.then(() => insertReportNumberPromise())
				.then(() => {
					Router.push({ name: 'GetCollectedPaymentsReport' })
				})
			console.log('reportHeaders.reportNumber', reportHeaders.reportNumber)
			console.log('reportHeaders.ourCompany', reportHeaders.ourCompany)
		}
		const firstDayOfWeek = 1
		return {
			reportData,
			reportHeaders,
			showWeekNumber,
			locale,
			reportType,
			createHeader,
			loading,
			tableData,
			columns,
			getData,
			radio,
			dateformat,
			firstDayOfWeek,
		}
	},
}
</script>

<style scoped>
.margin-top-15 {
	margin-top: 10px;
}
</style>
